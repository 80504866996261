export const LOAD_ESSAY_REQUEST = 'LOAD_ESSAY_REQUEST';
export const LOAD_ESSAY_SUCCESS = 'LOAD_ESSAY_SUCCESS';
export const LOAD_ESSAY_FAILED = 'LOAD_ESSAY_FAILED';

export const GET_ESSAY_REQUEST = 'GET_ESSAY_REQUEST';
export const GET_ESSAY_SUCCESS = 'GET_ESSAY_SUCCESS';
export const GET_ESSAY_FAILED = 'GET_ESSAY_FAILED';

export const DELETE_ESSAY_REQUEST = 'DELETE_ESSAY_REQUEST';
export const DELETE_ESSAY_SUCCESS = 'DELETE_ESSAY_SUCCESS';
export const DELETE_ESSAY_FAILED = 'DELETE_ESSAY_FAILED';

export const SEARCH_ESSAY_REQUEST = 'SEARCH_ESSAY_REQUEST';
export const SEARCH_ESSAY_SUCCESS = 'SEARCH_ESSAY_SUCCESS';
export const SEARCH_ESSAY_FAILED = 'SEARCH_ESSAY_FAILED';
