import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as routes from 'constants/routes';

const Index = () => {
  const [open, setOpen] = useState(true);
  const Menus = [
    { title: 'Dashboard', src: 'Chart_fill', to: routes.HOME },
    { title: 'Posts', src: 'Chat', gap: true, to: routes.POST },
    { title: 'Essays', src: 'Chat', to: routes.ESSAY },
    { title: 'Genocides', src: 'User', to: routes.GENOCIDE },
    { title: 'Television & Radio', src: 'media', gap: true, to: routes.MEDIA },
    { title: 'Maaveerars ', src: 'Calendar', to: routes.MAAVEERARS },
    { title: 'Categories ', src: 'Folder', gap: true, to: routes.CATEGORY },
    { title: 'Users', src: 'User', to: routes.USERS },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="flex">
      <div
        className={` ${
          open ? 'w-72' : 'w-20 '
        } bg-gray-800 h-screen pl-5  pr-5  pb-5 relative duration-300`}
      >
        <img
          src={require('assets/images/png/control.png')}
          className={`absolute cursor-pointer -right-3 top-4 w-7 border-dark-purple
           border-2 rounded-full  ${!open && 'rotate-180'}`}
          onClick={() => setOpen(!open)}
        />

        <ul className="pt-0">
          {Menus.map((Menu, index) => (
            <NavLink
              key={index}
              to={Menu.to}
              className={(item) =>
                classNames(
                  item?.isActive && 'bg-gray-600 font-bold	 ',
                  'flex rounded-md p-2 cursor-pointer text-gray-300 text-sm items-center gap-x-4 font-medium',
                  `${Menu.gap ? 'mt-9' : 'mt-2'}`
                )
              }
            >
              <img src={require(`assets/images/png/${Menu.src}.png`)} />
              <span className={`${!open && 'hidden'} origin-left duration-200`}>
                {Menu.title}
              </span>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Index;
