import axios from 'config/axois';
import { authURL } from 'config/env';
import * as types from 'store/auth/types';

export const login = (values) => {
  return async (dispatch) => {
    const responce = await axios.post(authURL, values);

    const loginSuccessAction = {
      type: types.LOGIN_SUCCESS,
      payload: responce.data,
    };
    dispatch(loginSuccessAction);
    return responce;
  };
};

export const logout = () => {
  const logoutAction = {
    type: types.PURGE,
    result: () => null,
  };

  return logoutAction;
};
