import React from 'react';
import Header from 'layouts/App/Header';
import SideBar from 'layouts/App/SideBar';
import Footer from 'layouts/App/Footer';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="min-h-full flex flex-col">
      <Header />
      <main className="flex  flex-row">
        <div className="h-full 	">
          <SideBar />
        </div>
        <div className="w-full">
          <Outlet />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
