import React from 'react';
import * as routes from 'constants/routes';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoMatch from 'pages/NoMatch';
import App from 'layouts/App';
import LazyPage from 'components/LazyPage';
import { isAuthorized as useAuthorized } from 'store/auth/selector';

function Router() {
  const isAuthorized = useSelector(useAuthorized);
  let location = useLocation();

  const PrivateRoute = ({ children }) => {
    if (!isAuthorized) {
      return <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
    }
    return children;
  };

  return (
    <Routes>
      <Route path={routes.LOGIN} element={<LazyPage page="Login" />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        }
      >
        <Route path={routes.HOME} element={<LazyPage page="Home" />} />
        {/* Maveerar related routes */}
        <Route
          path={routes.MAAVEERARS}
          element={<LazyPage page="Maaveerar" />}
        />
        <Route
          path={routes.MAAVEERAR_VIEW}
          element={<LazyPage page="Maaveerar/View" />}
        />
        <Route
          path={routes.MAAVEERAR_CREATE}
          element={<LazyPage page="Maaveerar/Create" />}
        />
        <Route
          path={routes.MAAVEERAR_EDIT}
          element={<LazyPage page="Maaveerar/Edit" />}
        />
        <Route path={routes.POST} element={<LazyPage page="Post" />} />
        /**Category related routed */
        <Route path={routes.CATEGORY} element={<LazyPage page="Category" />} />
        <Route
          path={routes.CATEGORY_VIEW}
          element={<LazyPage page="Category/View" />}
        />
        <Route
          path={routes.CATEGORY_CREATE}
          element={<LazyPage page="Category/Create" />}
        />
        <Route
          path={routes.CATEGORY_EDIT}
          element={<LazyPage page="Category/Edit" />}
        />
        /**user related routed */
        <Route path={routes.USERS} element={<LazyPage page="Setting/User" />} />
        <Route
          path={routes.USERS_VIEW}
          element={<LazyPage page="Setting/User/View" />}
        />
        <Route
          path={routes.USERS_CREATE}
          element={<LazyPage page="Setting/User/Create" />}
        />
        <Route
          path={routes.USERS_EDIT}
          element={<LazyPage page="Setting/User/Edit" />}
        />
        /**Media related routed */
        <Route path={routes.MEDIA} element={<LazyPage page="Media" />} />
        <Route
          path={routes.MEDIA_VIEW}
          element={<LazyPage page="Media/View" />}
        />
        <Route
          path={routes.MEDIA_CREATE}
          element={<LazyPage page="Media/Create" />}
        />
        <Route
          path={routes.MEDIA_EDIT}
          element={<LazyPage page="Media/Edit" />}
        />
        {/* Post related routes */}
        <Route path={routes.POST} element={<LazyPage page="Post" />} />
        <Route
          path={routes.POST_VIEW}
          element={<LazyPage page="Post/View" />}
        />
        <Route
          path={routes.POST_CREATE}
          element={<LazyPage page="Post/Create" />}
        />
        <Route
          path={routes.POST_EDIT}
          element={<LazyPage page="Post/Edit" />}
        />
        {/* ESSAY related routes */}
        <Route path={routes.ESSAY} element={<LazyPage page="Essay" />} />
        <Route
          path={routes.ESSAY_VIEW}
          element={<LazyPage page="Essay/View" />}
        />
        <Route
          path={routes.ESSAY_CREATE}
          element={<LazyPage page="Essay/Create" />}
        />
        <Route
          path={routes.ESSAY_EDIT}
          element={<LazyPage page="Essay/Edit" />}
        />
        {/* Genocide related routes */}
        <Route path={routes.GENOCIDE} element={<LazyPage page="Genocide" />} />
        <Route
          path={routes.GENOCIDE_VIEW}
          element={<LazyPage page="Genocide/View" />}
        />
        <Route
          path={routes.GENOCIDE_CREATE}
          element={<LazyPage page="Genocide/Create" />}
        />
        <Route
          path={routes.GENOCIDE_EDIT}
          element={<LazyPage page="Genocide/Edit" />}
        />
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default Router;
