export const LOAD_MAAVEERAR_REQUEST = 'LOAD_MAAVEERAR_REQUEST';
export const LOAD_MAAVEERAR_SUCCESS = 'LOAD_MAAVEERAR_SUCCESS';
export const LOAD_MAAVEERAR_FAILED = 'LOAD_MAAVEERAR_FAILED';

export const GET_MAAVEERAR_REQUEST = 'GET_MAAVEERAR_REQUEST';
export const GET_MAAVEERAR_SUCCESS = 'GET_MAAVEERAR_SUCCESS';
export const GET_MAAVEERAR_FAILED = 'GET_MAAVEERAR_FAILED';

export const DELETE_MAAVEERAR_REQUEST = 'DELETE_MAAVEERAR_REQUEST';
export const DELETE_MAAVEERAR_SUCCESS = 'DELETE_MAAVEERAR_SUCCESS';
export const DELETE_MAAVEERAR_FAILED = 'DELETE_MAAVEERAR_FAILED';
