import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Router } from 'router';
import { useStore } from '../src/store';
import ErrorBoundary from 'components/ErrorBoundary';
import Loading from 'components/Loading';
import Welcome from 'pages/Welcome';
import { name, lang } from 'config/env';

function App() {
  const { store, persist, isReady } = useStore();

  if (!isReady) {
    return <Welcome />;
  }

  return (
    <ErrorBoundary>
      {store && persist ? (
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persist}>
            <HelmetProvider>
              <Helmet>
                <html lang={lang} />
                <title>{name}</title>
              </Helmet>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </HelmetProvider>
          </PersistGate>
        </Provider>
      ) : (
        <Loading />
      )}
    </ErrorBoundary>
  );
}

export default App;
