import moment from 'moment';

export default function index() {
  return (
    <div as="nav" className="bg-gray-800 ">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-12">
          <span className="text-sm text-white sm:text-center ">
            © {moment().format('YYYY')}{' '}
            <a
              target="_blank"
              href="https://sangathamil.ca"
              className="hover:underline font-semibold	"
            >
              Sangathamil
            </a>
            . All Rights Reserved.
          </span>
          <div className="hidden md:block">
            <span className="text-sm text-white sm:text-center ">
              Design and developed by Sangathamil.ca
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
