export const LOAD_GENOCIDE_REQUEST = 'LOAD_GENOCIDE_REQUEST';
export const LOAD_GENOCIDE_SUCCESS = 'LOAD_GENOCIDE_SUCCESS';
export const LOAD_GENOCIDE_FAILED = 'LOAD_GENOCIDE_FAILED';

export const GET_GENOCIDE_REQUEST = 'GET_GENOCIDE_REQUEST';
export const GET_GENOCIDE_SUCCESS = 'GET_GENOCIDE_SUCCESS';
export const GET_GENOCIDE_FAILED = 'GET_GENOCIDE_FAILED';

export const DELETE_GENOCIDE_REQUEST = 'DELETE_GENOCIDE_REQUEST';
export const DELETE_GENOCIDE_SUCCESS = 'DELETE_GENOCIDE_SUCCESS';
export const DELETE_GENOCIDE_FAILED = 'DELETE_GENOCIDE_FAILED';

export const SEARCH_GENOCIDE_REQUEST = 'SEARCH_GENOCIDE_REQUEST';
export const SEARCH_GENOCIDE_SUCCESS = 'SEARCH_GENOCIDE_SUCCESS';
export const SEARCH_GENOCIDE_FAILED = 'SEARCH_GENOCIDE_FAILED';
