import * as types from 'store/category/types';

const initialState = {
  loading: false,
  categories: [],
  category: {},
  errors: [],
  paginationOption: {},
  searchCategories: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload?.data,
        paginationOption: action.payload?.meta,
      };
    case types.LOAD_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.GET_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    case types.GET_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.DELETE_CATEGORY_FAILED:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.SEARCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SEARCH_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        searchCategories: action.payload,
      };
    case types.SEARCH_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
