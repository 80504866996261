import * as types from 'store/genocide/types';

const initialState = {
  loading: false,
  genocides: [],
  genocide: {},
  errors: [],
  paginationOption: {},
  searchGenocides: [],
};

const genocideReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_GENOCIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_GENOCIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        genocides: action.payload?.data,
        paginationOption: action.payload?.meta,
      };
    case types.LOAD_GENOCIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.GET_GENOCIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_GENOCIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        genocide: action.payload,
      };
    case types.GET_GENOCIDE_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.DELETE_GENOCIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_GENOCIDE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_GENOCIDE_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.SEARCH_GENOCIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SEARCH_GENOCIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        searchGenocides: action.payload,
      };
    case types.SEARCH_GENOCIDE_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default genocideReducer;
