import * as types from 'store/user/types';

const initialState = {
  loading: false,
  users: [],
  errors: {},
  user: {},
  paginationOption: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload?.data,
        paginationOption: action.payload?.meta,
      };
    case types.LOAD_USER_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case types.GET_USER_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
