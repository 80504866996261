import { combineReducers } from 'redux';
import { persistReducer, PURGE } from 'redux-persist';
import persistConfig from 'config/persist';

import authReducer from 'store/auth/reducers';
import categoryReducer from 'store/category/reducers';
import mediaReducerr from 'store/media/reducers';
import maaveerarReducer from 'store/maaveerar/reducers';
import postReducer from 'store/post/reducers';
import userReducer from 'store/user/reducers';
import dashboardReducer from 'store/dashboard/reducers';
import essayReducer from './essay/reducers';
import genocideReducer from './genocide/reducers';

const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  category: categoryReducer,
  media: mediaReducerr,
  maaveerar: maaveerarReducer,
  post: postReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  essay: essayReducer,
  genocide: genocideReducer,
});

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
