import * as types from 'store/post/types';

const initialState = {
  loading: false,
  documentUploadLoading: false,
  posts: [],
  errors: [],
  paginationOption: [],
  post: {},
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload?.data,
        paginationOption: action.payload?.meta,
      };
    case types.LOAD_POST_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.GET_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        post: action.payload,
      };
    case types.GET_POST_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.DELETE_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_POST_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        documentUploadLoading: true,
      };
    case types.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentUploadLoading: false,
      };
    case types.UPLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        documentUploadLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default postReducer;
