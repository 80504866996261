import * as types from 'store/maaveerar/types';

const initialState = {
  loading: false,
  maaveerars: [],
  maaveerar: {},
  errors: {},
  paginationOption: {},
};

const maaveerarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_MAAVEERAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_MAAVEERAR_SUCCESS:
      return {
        ...state,
        loading: false,
        maaveerars: action.payload?.data,
        paginationOption: action.payload?.meta,
      };
    case types.LOAD_MAAVEERAR_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case types.GET_MAAVEERAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_MAAVEERAR_SUCCESS:
      return {
        ...state,
        loading: false,
        maaveerar: action.payload,
      };
    case types.GET_MAAVEERAR_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.DELETE_MAAVEERAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_MAAVEERAR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_MAAVEERAR_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default maaveerarReducer;
