export const LOAD_MEDIA_REQUEST = 'LOAD_MEDIA_REQUEST';
export const LOAD_MEDIA_SUCCESS = 'LOAD_MEDIA_SUCCESS';
export const LOAD_MEDIA_FAILED = 'LOAD_MEDIA_FAILED';

export const GET_MEDIA_REQUEST = 'GET_MEDIA_REQUEST';
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS';
export const GET_MEDIA_FAILED = 'GET_MEDIA_FAILED';

export const DELETE_MEDIA_REQUEST = 'DELETE_MEDIA_REQUEST';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILED = 'DELETE_MEDIA_FAILED';
