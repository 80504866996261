export const HOME = '/home';
export const LOGIN = '/login';
/** user related routes */
export const USERS = '/users';
export const USERS_CREATE = '/users/create';
export const USERS_VIEW = '/users/:id';
export const USERS_EDIT = '/users/:id/edit';
/** caregory related routes */
export const CATEGORY = '/categories';
export const CATEGORY_CREATE = '/categories/create';
export const CATEGORY_VIEW = '/categories/:id';
export const CATEGORY_EDIT = '/categories/:id/edit';
/** media related routes */
export const MEDIA = '/medias';
export const MEDIA_CREATE = '/medias/create';
export const MEDIA_VIEW = '/medias/:id';
export const MEDIA_EDIT = '/medias/:id/edit';

//
/** maaveerar related routes */
export const MAAVEERARS = '/maaveerars';
export const MAAVEERAR_CREATE = '/maaveerars/create';
export const MAAVEERAR_VIEW = '/maaveerars/:id';
export const MAAVEERAR_EDIT = '/maaveerars/:id/edit';
/** post related routes */
export const POST = '/posts';
export const POST_CREATE = '/posts/create';
export const POST_VIEW = '/posts/:id';
export const POST_EDIT = '/posts/:id/edit';

/** essay related routes */
export const ESSAY = '/essays';
export const ESSAY_CREATE = '/essays/create';
export const ESSAY_VIEW = '/essays/:id';
export const ESSAY_EDIT = '/essays/:id/edit';

/** genocide related routes */
export const GENOCIDE = '/genocides';
export const GENOCIDE_CREATE = '/genocides/create';
export const GENOCIDE_VIEW = '/genocides/:id';
export const GENOCIDE_EDIT = '/genocides/:id/edit';
