import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Logo from 'assets/images/png/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { HOME, LOGIN } from 'constants/routes';
import { user as useUser } from 'store/auth/selector';

import { Disclosure, Menu } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { logout } from 'store/auth/actions';

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(useUser);

  const onLogout = useCallback(() => {
    dispatch(logout());
    navigate(LOGIN);
    window.location.reload();
  }, [dispatch]);

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className=" mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Link to={HOME}>
                        <img className="w-32" src={Logo} alt="Workflow" />
                      </Link>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <Menu as="div" className="ml-3 relative">
                        <div className=" flex items-center  ">
                          <div className="p-3">
                            <div className="text-base font-medium leading-none text-white">
                              {user?.name}
                            </div>
                            <div className="text-sm font-medium leading-none text-gray-400">
                              {user?.email}
                            </div>
                          </div>
                          <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user?.image}
                              alt=""
                            />
                          </Menu.Button>
                        </div>

                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Button>
                            <button onClick={() => onLogout()}>
                              <span className="block px-4 py-2 text-sm text-gray-700 font-bold">
                                Sign out
                              </span>
                            </button>
                          </Menu.Button>
                        </Menu.Items>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={user?.image}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {user?.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {user?.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Disclosure.Button
                      as="div"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                    >
                      <button onClick={() => onLogout()}>Sign out</button>
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
