import * as types from 'store/media/types';

const initialState = {
  loading: false,
  medias: [],
  paginationOption: {},
  media: {},
  errors: {},
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        medias: action.payload?.data,
        paginationOption: action.payload?.meta,
      };
    case types.LOAD_MEDIA_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.GET_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        media: action.payload,
      };
    case types.GET_MEDIA_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case types.DELETE_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_MEDIA_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default mediaReducer;
